import { isCoordInElement } from "../../utils/typescript/helpers";

const dialogs: NodeListOf<HTMLDialogElement> = document.querySelectorAll(
  'dialog[id^="dialog-"]',
);
dialogs.forEach((dialog) => {
  const trigger: string = dialog.dataset.trigger as string;
  const dialogTrigger = document.querySelectorAll<HTMLElement>(trigger);
  const closeButtons = dialog.querySelectorAll(".close");

  let originalOverflow = document.body.style.overflow;

  function handleOpen() {
    originalOverflow = document.body.style.overflow;
    document.body.style.overflow = "hidden";

    dialog.showModal();
  }

  dialogTrigger.forEach((t) => {
    t.addEventListener("click", handleOpen);
    t.addEventListener("keydown", (e) => {
      if (e.key === "Enter" || e.key === " ") {
        handleOpen();
      }
    });
  });
  dialog?.addEventListener(
    "close",
    () => (document.body.style.overflow = originalOverflow),
  );
  dialog?.addEventListener("click", (event) => {
    if (!isCoordInElement(dialog, event.clientX, event.clientY)) dialog.close();
  });
  closeButtons.forEach((closeButton) => {
    closeButton.addEventListener("click", () => {
      dialog.close();
    });
  });
});

dialogs.forEach((dialog) => {
  const trigger: string = dialog.dataset.trigger as string;
  const closeTrigger = dialog.querySelector("[data-on-close-event]");

  if (!trigger.startsWith("[role='button']")) {
    const dialogTrigger: HTMLElement | null = document.querySelector(
      `[data-${trigger}]`,
    );
    const dialogContent: HTMLDivElement | null = dialog.querySelector(
      "div[data-dialog-content]",
    );
    const onOpenEvent = dialog.dataset.onOpenEvent as string;
    const onCloseEvent = dialog.querySelector("[on-close-event]");

    if (!dialogTrigger || !dialogContent)
      return console.warn(`Dialog ${dialog.id} is missing a required element.`);

    closeTrigger?.addEventListener("click", (event) => {
      event.stopPropagation();

      if (onCloseEvent !== undefined) {
        document.dispatchEvent(new CustomEvent("dialog.onclose"));
      }
    });

    dialogTrigger?.addEventListener("click", (event) => {
      event.stopPropagation();

      dialog.showModal();

      if (onOpenEvent !== undefined) {
        document.dispatchEvent(new CustomEvent("dialog.onopen"));
      }
    });
    dialogContent &&
      dialog?.addEventListener(
        "close",
        () => (document.body.style.overflow = "auto"),
      );
    dialogContent &&
      dialog?.addEventListener("click", (event) => {
        if (!isCoordInElement(dialogContent, event.clientX, event.clientY))
          dialog.close();
      });
  }
});
